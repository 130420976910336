import { Listing, PublishStatus, Seller } from "@prisma/client";
import prisma, { Paginated, paginateModel } from "../../../prisma";
import { CreateListingData } from "../interfaces/ListingData";
import ListingsHelper from "@/utils/ListingsHelper";
import LocationController from "./LocationController";
import UserService from "../services/user/UserService";
import { TelegramService } from "../services/TelegramService";

export default class ListingController {
  static async getAllListings(
    page: number = 1,
    pageSize: number = 15,
    query: any = null
  ): Promise<Paginated> {
    try {
      let queryParamsWhere = query
        ? ListingsHelper.setSearchWhereFromQueryParams(query)
        : null;
      let where = {
        publish_status: {
          equals: PublishStatus.published,
        },
        deleted: {
          equals: !true,
        },
        ...queryParamsWhere,
      } as any;

      let locationsIdsToSearch = [];

      if (query?.seller) {
        where = {
          ...where,
          seller: {
            uuid: {
              equals: query.seller,
            },
          },
        };
      }

      if (query?.location) {
        locationsIdsToSearch = await LocationController.getLocationIdsToSearch(
          query.location
        );

        where = {
          ...where,
          locationId: {
            in: locationsIdsToSearch,
          },
        };
      }

      let orderBy = ListingsHelper.getOrderByFromQueryParams(query);

      return await paginateModel(
        prisma.listing as any,
        where,
        page,
        pageSize,
        orderBy
      );
    } catch (error) {
      throw error;
    }
  }
  static async getAllMyListings(
    page: number = 1,
    pageSize: number = 15,
    sellerId: number,
    query: any = null
  ): Promise<Paginated> {
    try {
      let queryParamsWhere = query
        ? ListingsHelper.setSearchWhereFromQueryParams(query)
        : null;
      let where = {
        seller_id: {
          equals: sellerId,
        },

        deleted: {
          equals: !true,
        },

        ...queryParamsWhere,
      } as any;

      let locationsIdsToSearch = [];

      if (query?.location) {
        locationsIdsToSearch = await LocationController.getLocationIdsToSearch(
          query.location
        );

        where = {
          ...where,
          locationId: {
            in: locationsIdsToSearch,
          },
        };
      }

      let orderBy = ListingsHelper.getOrderByFromQueryParams(query);

      return await paginateModel(
        prisma.listing as any,
        where,
        page,
        pageSize,
        orderBy
      );
    } catch (error) {
      throw error;
    }
  }

  static async getOne(uuid: string, req?: any): Promise<Listing> {
    try {
      let seller;

      try {
        seller = await UserService.getLoggedInUser(req);
      } catch (e) {}

      let where = {
        uuid: {
          equals: uuid,
        },
        deleted: {
          equals: !true,
        },
      } as any;

      let listing = await prisma.listing.findFirst({
        where,
        include: this.setAdditionalListingColumns(),
      });

      if (seller && listing?.seller_id === seller?.id) {
        return listing;
      } else {
        if (listing?.publish_status === PublishStatus.published) {
          return listing;
        } else {
          return null;
        }
      }
    } catch (error) {
      throw error;
    }
  }

  static async getAnyListing(uuid: string): Promise<Listing> {
    try {
      let where = {
        uuid: {
          equals: uuid,
        },
      } as any;

      let listing = await prisma.listing.findFirst({
        where,
        include: this.setAdditionalListingColumns(),
      });

      return listing;
    } catch (error) {
      throw error;
    }
  }

  static async getOneBySeller(
    uuid: string,
    sellerId: number
  ): Promise<Listing> {
    try {
      let where = {
        seller_id: {
          equals: sellerId,
        },
        uuid: {
          equals: uuid,
        },
        deleted: {
          equals: !true,
        },
      } as any;

      return prisma.listing.findFirst({
        where,
        include: this.setAdditionalListingColumns(),
      });
    } catch (error) {
      throw error;
    }
  }

  static async getPreviewWithToken(
    uuid: string,
    approval_token: string
  ): Promise<Listing> {
    try {
      let where = {
        approval_token: {
          equals: approval_token,
        },
        uuid: {
          equals: uuid,
        },
        deleted: {
          equals: !true,
        },
      } as any;

      return prisma.listing.findFirst({
        where,
        include: this.setAdditionalListingColumns(),
      });
    } catch (error) {
      throw error;
    }
  }

  static setAdditionalListingColumns() {
    return {
      seller: {
        select: {
          id: true,
          uuid: true,
          name: true,
          phone: true,
          thumbnail_path: true,
          ig_id: true,
          ig_name: true,
          whatsapp_number: true,
        },
      },
      location: {
        select: {
          id: true,
          uuid: true,
          name: true,
          full_name: true,
        },
      },
      media: {
        select: {
          id: true,
          uuid: true,
          attachment: {
            select: {
              id: true,
              uuid: true,
              path: true,
              type: true,
              name: true,
            },
          },
        },
      },
    };
  }

  static async add(ListingData: CreateListingData) {
    try {
      const Listing = await prisma.listing.create({
        data: ListingData as any,
      });
      return { success: true, Listing };
    } catch (error) {
      throw error;
    }
  }

  static async publishListing(uuid: string) {
    try {
      return await prisma.listing.update({
        where: {
          uuid: uuid,
        },
        data: {
          publish_status: PublishStatus.published,
        },
      });
    } catch (error) {
      throw error;
    }
  }

  static async deleteAllSellerRawListing(sellerId: number) {
    try {
      return await prisma.rawListingData.deleteMany({
        where: {
          seller_id: sellerId,
        },
      });
    } catch (error) {
      throw error;
    }
  }

  static async deleteAllSellerPosts(seller: Seller, deleted_by: string) {
    await prisma.listing.updateMany({
      where: {
        seller_id: seller.id,
      },
      data: {
        publish_status: PublishStatus.archived,
        deleted: true,
        deleted_at: new Date(),
        deleted_by: deleted_by,
      },
    });

    TelegramService.sendMessage(
      `🚨 ${seller.name} posts have been deleted by ${deleted_by}`
    );

    this.deleteAllSellerRawListing(seller.id);
  }

  static async getPublishRequests() {
    try {
      let where = {
        publish_status: {
          equals: PublishStatus.requested_publish,
        },

        deleted: {
          equals: !true,
        },
      } as any;

      return await paginateModel(prisma.listing as any, where, 1, 50, {
        createdAt: "desc",
      });
    } catch (error) {
      throw error;
    }
  }
}
