import { Prisma, PublishStatus, Seller } from "@prisma/client";
import prisma, { Paginated, paginateModel } from "../../../prisma";
import SellerService from "../services/seller/SellerService";
import LocationController from "./LocationController";
import ListingController from "./ListingController";
import CommonUtils from "@/utils/CommonUtils";

export default class SellerController {
  static async getAllPaginated(
    page: number = 1,
    pageSize: number = 10
  ): Promise<Paginated> {
    try {
      let where = {
        publish_status: {
          equals: PublishStatus.published,
        },
        deleted: {
          equals: !true,
        },
      } as any;

      return await paginateModel(prisma.listing as any, where, page, pageSize);
    } catch (error) {
      throw error;
    }
  }

  static async getOneById(id: number): Promise<Seller> {
    try {
      let where = {
        id: {
          equals: id,
        },
        deleted: {
          equals: !true,
        },
      } as any;

      return prisma.seller.findFirst({
        where,
      });
    } catch (error) {
      throw error;
    }
  }
  static async getOneByUuid(uuid: string): Promise<Seller> {
    try {
      let where = {
        uuid: {
          equals: uuid,
        },
        deleted: {
          equals: !true,
        },
      } as any;

      return prisma.seller.findFirst({
        where,
      });
    } catch (error) {
      throw error;
    }
  }

  static async updateSellerPhoneNumberFromText(
    sellerUuid: string,
    text: string
  ): Promise<Seller> {
    let seller = await prisma.seller.findFirst({
      where: {
        uuid: sellerUuid,
        deleted: !true,
      },
    });

    if (!seller) {
      throw new Error("Seller not found: " + sellerUuid);
    }

    let profileDataUpdateDuration = 30 * 24 * 60 * 60 * 1000; // 30 days

    let phoneNumber = SellerService.extractPhoneNumber(text);
    let updateDurationPassed =
      seller.last_profile_data_update?.getTime() <
        new Date().getTime() - profileDataUpdateDuration &&
      phoneNumber != seller.phone;

    if (phoneNumber) {
      seller = await prisma.seller.update({
        where: {
          uuid: sellerUuid,
        },
        data: {
          phone:
            seller.phone?.length < 10 || updateDurationPassed || !seller.phone
              ? phoneNumber
              : seller.phone,
          whatsapp_number:
            seller.whatsapp_number?.indexOf("255") != 0 ||
            !seller.whatsapp_number ||
            updateDurationPassed
              ? "255" + phoneNumber.slice(-9)
              : seller.whatsapp_number,
          last_profile_data_update: new Date(),
        },
      });
    }
    return seller;
  }

  static async saveInstagramSellerIfNotExists(
    ig_username: string
  ): Promise<string> {
    let seller = await prisma.seller.findFirst({
      where: {
        ig_id: ig_username,
        deleted: !true,
      },
    });
    let total = await prisma.seller.count();
    if (!seller) {
      seller = await prisma.seller.create({
        data: {
          name: ig_username,
          type: "agent",
          ig_name: ig_username,
          ig_id: ig_username,
          allow_sync: true,
          last_sync_date: new Date("2021-01-01"),
        } as any,
      });
      SellerService.updateSellerInfo(seller.uuid);
      total++;
      return `created, total: ${total}`;
    }

    return `exists, total: ${total}`;
  }

  static async saveMultipleSellers(sellers: string): Promise<string> {
    if (!sellers) {
      throw new Error("No sellers provided");
    }

    sellers = sellers.replace(/(\r\n|\n|\r)/gm, "");
    sellers = sellers.replace(/[^a-zA-Z0-9_.,\s]/g, "");

    let ig_usernames = sellers.split(",");

    let saved = 0;
    let exists = 0;

    for (let i = 0; i < ig_usernames.length; i++) {
      ig_usernames[i] = ig_usernames[i].trim();

      if (!ig_usernames[i]) {
        continue;
      }

      let ig_username = ig_usernames[i];

      let seller = await prisma.seller.findFirst({
        where: {
          ig_id: ig_username,
          deleted: !true,
        },
      });

      if (!seller) {
        seller = await prisma.seller.create({
          data: {
            name: ig_username,
            type: "agent",
            ig_name: ig_username,
            ig_id: ig_username,
            allow_sync: true,
            last_sync_date: new Date("2021-01-01"),
          } as any,
        });

        if (ig_usernames.length == 1) {
          SellerService.updateSellerInfo(seller.uuid);
        }

        saved++;
      } else {
        exists++;
      }
    }

    let count = await prisma.seller.count();
    return `Saved: ${saved}, Exists: ${exists}, Total: ${count}`;
  }

  static async getAllWithLocationsPaginated(
    page: number = 1,
    pageSize: number = 12,
    context: any = null
  ): Promise<Paginated> {
    try {
      const offset = (page - 1) * pageSize;

      let locationsIdsToSearch = [];

      if (context?.query?.location) {
        locationsIdsToSearch = await LocationController.getLocationIdsToSearch(
          context.query.location
        );
      }

      let keyword = context?.query?.query;

      let whereQuery = `
      WHERE (${
        locationsIdsToSearch.length > 0
          ? `l."id" IN (${locationsIdsToSearch.join(",")})`
          : "1=1"
      })
      AND (${
        keyword
          ? `
          s."name" LIKE '%${keyword}%' 
          OR l."name" LIKE '%${keyword}%'
          OR l."full_name" LIKE '%${keyword}%'
          OR s."ig_name" LIKE '%${keyword}%'
          OR s."profile_data" LIKE '%${keyword}%'
          `
          : "1=1"
      })
      AND s."active" = true
            `;

      const totalCountQuery = `
            SELECT COUNT(DISTINCT s."id")
            FROM "seller_locations" sl
            JOIN "Seller" s ON s."id" = sl."seller_id"
            JOIN "Location" l ON l."id" = sl."location_id"
            ${whereQuery}
        `;
      const totalRows = await prisma.$queryRaw(
        Prisma.sql`${Prisma.raw(totalCountQuery)}`
      );

      const dataQuery = `
            SELECT 
            s."id", 
            s."uuid", 
            s."name", 
            s."ig_name", 
            s."ig_id", 
            s."thumbnail_path",
            s."phone",
            s."whatsapp_number", 
            ARRAY_AGG(
                JSON_BUILD_OBJECT(
                    'locationId', l."id",
                    'locationName', l."name",
                    'full_name', l."full_name"
                )
            ) AS locations
            FROM "seller_locations" sl
            JOIN "Seller" s ON s."id" = sl."seller_id"
            JOIN "Location" l ON l."id" = sl."location_id"
            ${whereQuery}
            GROUP BY s."id", s."name"
            ORDER BY s."name"
            LIMIT ${pageSize} OFFSET ${offset};
        `;

      const data = await prisma.$queryRaw(Prisma.sql`${Prisma.raw(dataQuery)}`);

      const total = Number(totalRows[0].count);
      const totalPages = Math.ceil(total / pageSize);

      return {
        data: data,
        total: total,
        page: page,
        pageSize: pageSize,
        totalPages: totalPages,
        hasNext: total > page * pageSize,
        hasPrevious: page > 1,
      };
    } catch (error) {
      throw error;
    }
  }

  static async getSellerByInstagramUserId(
    instagram_user_id: string
  ): Promise<Seller> {
    let seller = await prisma.seller.findFirst({
      where: {
        profile_data: {
          contains: '"id":"' + instagram_user_id + '"',
        },
        deleted: !true,
      },
    });

    return seller;
  }

  static async switchInstagramSync(seller_uuid, allow_sync) {
    let seller = await prisma.seller.findUnique({
      where: { uuid: seller_uuid },
    });

    seller = await prisma.seller.update({
      where: {
        id: seller.id,
      },
      data: {
        allow_sync: allow_sync,
        // last_sync_date: new Date("2020-01-01"),
      },
    });

    if (!allow_sync && CommonUtils.isTestSeller(seller)) {
      ListingController.deleteAllSellerRawListing(seller.id);
    }
  }

  static async getSellerWithInstagramCredentials(seller_id: number) {
    let seller = await prisma.seller.findUnique({
      where: { id: seller_id },
      select: {
        profile_data: true,
        ig_id: true,
        ig_name: true,
        ig_token: true,
        ig_token_expires_at: true,
      },
    });

    if (!seller) {
      throw new Error("Seller not found");
    }

    return seller;
  }
}
