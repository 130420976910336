import { LabelValuePair } from "@/interfaces/LabelValuPair";
import { DisplayPriceLabel } from "@/interfaces/list";
import { ListingData } from "@/server/interfaces/ListingData";
import { Listing, ListingType, PropertyType, UsageType } from "@prisma/client";
import ListingDescriptionHelper from "./ListingDescriptionHelper";
import LocationController from "@/server/controllers/LocationController";
import SellerController from "@/server/controllers/SellerController";

export default class ListingsHelper {
  static getImagePath(Listing: Listing) {
    return (
      "https://makazimapya.s3.amazonaws.com/public/ads/images/" +
      Listing.thumbnail_file_name
    );
  }

  static getLabels = (Listing: ListingData): string[] => {
    let labels: string[] = [];

    if (Listing.size && Listing.size_unit) {
      labels.push(Listing.size + "" + Listing.size_unit);
    }

    if (Listing.installment_allowed) {
      labels.push("By Installment");
    }

    if (Listing.for_agriculture) {
      labels.push("Agriculture");
    }

    if (Listing.for_residential) {
      labels.push("Residential");
    }

    if (Listing.for_industrial) {
      labels.push("Industrial");
    }

    if (Listing.is_project) {
      labels.push("Project");
    }

    return labels;
  };

  static getLabelsAndValues = (Listing: ListingData): LabelValuePair[] => {
    let labels: LabelValuePair[] = [];

    if (Listing.size && Listing.size_unit) {
      labels.push({
        label: "Size",
        value: Listing.size + "" + Listing.size_unit,
      });
    }

    if (Listing.installment_allowed) {
      labels.push({
        label: "Installment Allowed",
        value: "Yes",
      });
    }

    let usage = this.getListingUses(Listing);

    if (usage.length) {
      labels.push({
        label: "Installment Allowed",
        value: usage.join(","),
      });
    }

    if (Listing.is_project) {
      labels.push({
        label: "Project",
        value: "Yes",
      });
    }

    return labels;
  };

  static getListingUses(Listing: ListingData) {
    let usage: string[] = [];
    if (Listing.for_agriculture) {
      usage.push("Agriculture");
    }

    if (Listing.for_residential) {
      usage.push("Residential");
    }

    if (Listing.for_industrial) {
      usage.push("Industrial");
    }
    return usage;
  }

  static getPriceLabels = (listing: ListingData): DisplayPriceLabel => {
    let priceLabel: DisplayPriceLabel = {
      currency: listing.currency == "tzs" ? "Sh." : "$",
      price: null,
      label: null,
    };

    switch (listing.price_label) {
      case "per_sqm":
        priceLabel = {
          ...priceLabel,
          label: " per sqm",
          price: listing.price,
        };
        break;

      case "per_acre":
        priceLabel = {
          ...priceLabel,
          label: " per acre",
          price: listing.price,
        };
        break;

      case "per_day":
        priceLabel = {
          ...priceLabel,
          label: " per day",
          price: listing.price,
        };
        break;

      case "per_month":
        priceLabel = {
          ...priceLabel,
          label: " per month",
          price: listing.price,
        };
        break;

      case "per_year":
        priceLabel = {
          ...priceLabel,
          label: " per year",
          price: listing.price,
        };
        break;

      default:
        priceLabel = {
          ...priceLabel,
          label: null,
          price: listing.price,
        };
        break;
    }

    return priceLabel;
  };

  static setSearchWhereFromQueryParams(
    queryParams: any,
    locations: number[] = []
  ) {
    let where = {} as any;

    where = this.setPropertyAndBuildingTypeWhereFromQueryParams(
      where,
      queryParams
    );

    if (locations?.length) {
      where.locationId = {
        in: locations,
      };
    }

    if (queryParams.listing_type) {
      where.listing_type = {
        equals: queryParams.listing_type,
      };
    }

    if (queryParams.minPrice && queryParams.maxPrice) {
      where.price = {
        gte: parseFloat(queryParams.minPrice),
        lte: parseFloat(queryParams.maxPrice),
      };
    } else if (queryParams.maxPrice) {
      where.price = {
        lte: parseFloat(queryParams.maxPrice),
      };
    } else if (queryParams.minPrice) {
      where.price = {
        gte: parseFloat(queryParams.minPrice),
      };
    }

    if (queryParams.minSize) {
      where.size = {
        gte: parseFloat(queryParams.minSize),
      };
    }

    if (queryParams.maxSize) {
      where.size = {
        lte: parseFloat(queryParams.maxSize),
      };
    }

    if (queryParams.query) {
      where.OR = [
        {
          title: {
            search: this.getFullTextSearchArrayFromStr(queryParams.query),
            mode: "insensitive",
          },
        },
        {
          description: {
            search: this.getFullTextSearchArrayFromStr(queryParams.query),
            mode: "insensitive",
          },
        },
      ];
    }
    return where;
  }

  static setPropertyAndBuildingTypeWhereFromQueryParams(
    where: any,
    queryParams: any
  ) {
    let propertyType = queryParams.property_type;

    switch (propertyType) {
      case "plot":
      case "residential_plot":
        where.property_type = {
          equals: "land",
        };
        where.usage_type = {
          equals: "residential",
        };
        break;

      case "farm":
        where.property_type = {
          equals: "land",
        };
        where.usage_type = {
          equals: "agriculture",
        };
        break;

      case "house":
        where.property_type = {
          equals: "building",
        };
        where.building_listing_type = {
          equals: "house",
        };
        break;

      case "retail_space":
        where.property_type = {
          equals: "building",
        };
        where.building_listing_type = {
          equals: "retail_space",
        };
        break;

      case "office_space":
        where.property_type = {
          equals: "building",
        };
        where.building_listing_type = {
          equals: "office_space",
        };
        break;

      case "warehouse":
        where.property_type = {
          equals: "building",
        };
        where.usage_type = {
          equals: "industrial",
        };
        break;

      case "industrial_plot":
        where.OR = [
          {
            property_type: {
              equals: "land",
            },
            usage_type: {
              equals: "industrial",
            },
          },
          {
            property_type: {
              equals: "building",
            },
            usage_type: {
              equals: "commercial",
            },
          },
        ];

        break;
    }

    return where;
  }

  static getPropertyTypeAndUsageTypeFromFromPropertyType(propertyType: string) {
    let property_type: PropertyType = "land";
    let usage_type: UsageType = "residential";

    switch (propertyType) {
      case "plot":
      case "residential_plot":
        property_type = "land";
        usage_type = "residential";
        break;

      case "farm":
        property_type = "land";
        usage_type = "agriculture";
        break;

      case "house":
        property_type = "building";
        usage_type = "residential";
        break;

      case "retail_space":
        property_type = "building";
        usage_type = "commercial";
        break;

      case "office_space":
        property_type = "building";
        usage_type = "commercial";
        break;

      case "warehouse":
        property_type = "building";
        usage_type = "industrial";
        break;

      case "industrial_plot":
        property_type = "land";
        usage_type = "industrial";
        break;
    }

    return {
      property_type,
      usage_type,
    };
  }

  static getOrderByFromQueryParams(queryParams: any) {
    let orderBy = {} as any;
    if (queryParams.orderBy) {
      if (queryParams.orderBy == "price_asc") {
        orderBy.price = "asc";
      } else if (queryParams.orderBy == "price_desc") {
        orderBy.price = "desc";
      } else if (queryParams.orderBy == "size_asc") {
        orderBy.size = "asc";
      } else if (queryParams.orderBy == "size_desc") {
        orderBy.size = "desc";
      } else if (queryParams.orderBy == "date_desc") {
        orderBy.createdAt = "desc";
      } else if (queryParams.orderBy == "date_asc") {
        orderBy.createdAt = "asc";
      } else if (queryParams.orderBy == "relevance" && queryParams.query) {
        orderBy._relevance = {
          fields: ["title", "description"],
          search: this.getFullTextSearchArrayFromStr(queryParams.query),
          sort: "desc",
        };
      } else {
        orderBy.id = "desc";
      }
    } else {
      orderBy.id = "desc";
    }
    return orderBy;
  }

  static getFullTextSearchArrayFromStr2(str: any): string[] {
    if (typeof str !== "string") {
      console.error("Input is not a string:", str);
      return [];
    }

    if (!str.trim()) return [];

    const arr = str.split(" ");
    const keywords = arr
      .map((keyword) => keyword.replace(/[,\.]/g, "").trim())
      .filter((keyword) => keyword.length > 0);

    return keywords;
  }

  static getFullTextSearchArrayFromStr(str: any) {
    if (typeof str !== "string") {
      console.error("Input is not a string:", str);
      return "";
    }
    if (!str) return "";
    if (str.length < 3) return str;
    let arr = str.split(" ");
    let searchString = "";
    let keyword = "";
    for (let i = 0; i < arr.length; i++) {
      keyword = arr[i].replace(/[,\.]/g, "").trim();
      if (keyword.length > 1) {
        searchString += `${keyword} | `;
      }
    }
    return searchString.slice(0, -3);
  }

  static async getQueryPramsInfo(queryParams: any, locale: "" | "en" = "") {
    let listing_type = queryParams.listing_type;
    let payment_type = queryParams.payment_type;

    let location = null;

    if (queryParams.location) {
      location = await LocationController.getOne(queryParams.location);
    }

    let seller = null;

    if (queryParams.seller) {
      seller = await SellerController.getOneByUuid(queryParams.seller);
    }

    return ListingDescriptionHelper.getQueryPramsInfo(
      queryParams,
      location?.full_name || "",
      seller,
      locale
    );
  }

  static createSlugFromTitle(title: string) {
    if (!title) return "";
    return title
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  }

  static getLocationLinkForSimilarListings(listing: any) {
    let locationUuid = listing.location.uuid;
    let listingType = listing.listing_type;
    let propertyType = listing.property_type;

    let link = `listings?location=${locationUuid}&listing_type=${listingType}&property_type=${propertyType}`;

    return link;
  }

  static getLocationLinkForSimilarListingsByLocationId(
    listing: any,
    locationUuid: string
  ) {
    let listingType = listing.listing_type;
    let propertyType = listing.property_type;

    let link = `listings?location=${locationUuid}&listing_type=${listingType}&property_type=${propertyType}`;

    return link;
  }

  static getPostingPropertyTypes(t: any) {
    return [
      {
        label: t.select,
        value: "",
        for: ["sale", "rent"],
      },
      {
        label: t.residentialPlot,
        value: "residential_plot",
        for: ["sale"],
      },
      {
        label: t.house,
        value: "house",
        for: ["sale", "rent"],
      },
      {
        label: "Apartment",
        value: "apartment",
        for: ["sale", "rent"],
      },
      {
        label: t.farm,
        value: "farm",
        for: ["sale"],
      },
      {
        label: t.commercialPlot,
        value: "industrial_plot",
        for: ["sale"],
      },
      {
        label: t.retailSpace,
        value: "retail_space",
        for: ["rent"],
      },
      {
        label: t.officeSpace,
        value: "office_space",
        for: ["rent"],
      },
      {
        label: t.warehouse,
        value: "warehouse",
        for: ["rent"],
      },
    ] as any[];
  }

  static getPaymentModes(t: any) {
    return [
      {
        label: "Lump sum",
        value: "total",
        priceLabel: "Price",
      },
      {
        label: "Monthly",
        value: "monthly",
        priceLabel: "Monthly Price",
      },
      {
        label: "Per sqm",
        value: "per_sqm",
        priceLabel: "Price per sqm",
      },
      {
        label: "Per acre",
        value: "per_acre",
        priceLabel: "Price per acre",
      },
      {
        label: "Per 3 months",
        value: "per_quarter",
        priceLabel: "Price per 3 months",
      },
      {
        label: "Per 6 months",
        value: "semi-annually",
        priceLabel: "Price per 6 months",
      },
      {
        label: "Per year",
        value: "annually",
        priceLabel: "Price per year",
      },
    ] as any[];
  }

  static getPostingOfferTypes(t: any) {
    const offer_types = [
      {
        label: t.select,
        value: "",
      },
      {
        label: t.listingPostForSale,
        value: "sale",
      },
      {
        label: t.listingPostForRent,
        value: "rent",
      },
    ] as any[];
    return offer_types;
  }
}
