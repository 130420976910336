import { RawListingData } from "./../../../../node_modules/.prisma/client/index.d";
import { PublishStatus, Seller } from "@prisma/client";
import prisma from "../../../../prisma";
import FilesService from "../files/filesService";
import GeneralService from "../GeneralService";
import { Facebook } from "../providers/fb";
import { TelegramService } from "../TelegramService";
import ListingController from "@/server/controllers/ListingController";

export default class SellerService {
  static async getInstagramSellerInfo(igId: string): Promise<any> {
    let fields = `business_discovery.username(${igId}){id,name,profile_picture_url,biography,website}`;

    try {
      let res = await Facebook.query(fields);

      if (res.error) {
        throw new Error(res.error.message);
      }

      if (!res.business_discovery) {
        throw new Error("No business discovery data found");
      }

      return res;
    } catch (e) {
      console.log("exception at getInstagramSellerInfo: ", fields);
      throw new Error(e);
    }
  }

  static extractPhoneNumber(text: string): string | null {
    const chunkSize = 1000; // Size of each chunk to process
    let start = 0;
    let end = chunkSize;
    const regex: RegExp =
      /(\+?\d{1,3}[-\s]?\(?\d{1,4}\)?[-\s]?\d{1,4}[-\s]?\d{1,4}|\b\d{10,12}\b)|phone=(\d+)/;

    while (start < text.length) {
      const chunk = text.substring(start, end);
      const matches: RegExpMatchArray | null = chunk.match(regex);
      if (matches) {
        for (const match of matches) {
          if (match) {
            let phoneNumber: string = match.replace(/\D/g, "");
            phoneNumber = phoneNumber.trim();
            if (phoneNumber.length >= 10 && phoneNumber.length <= 12) {
              return phoneNumber;
            }
          }
        }
      }
      start += chunkSize;
      end += chunkSize;
      end = end > text.length ? text.length : end;
    }

    return null;
  }

  static extractInstagramUsernameFromPostLinkText(content: string): string {
    const regex: RegExp =
      /href="https:\/\/www\.instagram\.com\/([^\/]+)\/(p|reel)\//;

    const match = content.match(regex);

    let username = "";
    if (match) {
      username = match[1];
    } else {
      console.log("No match found");
    }
    return username;
  }

  static async getWhatsAppNumberFromFromWhatsAppURL(
    whatsAppUrl: string
  ): Promise<string> {
    let response = await GeneralService.getURLContents(whatsAppUrl);
    let text = await response?.data;
    if (!text) {
      return "";
    }
    let phoneNumber = this.extractPhoneNumber(text);

    if (!phoneNumber) {
      console.log("No phone number found in WhatsApp URL");
      return "";
    }

    if (!(phoneNumber.length >= 10 && phoneNumber.length <= 12)) {
      return "";
    }

    return phoneNumber;
  }

  static async getSellerFromLink(igLink: string): Promise<any> {
    let response = await GeneralService.getURLContents(igLink);
    let text = await response.data;

    return text;
  }

  public static async upsertIGAccount(
    ig_username: string,
    name: string,
    access_token: string,
    access_token_expires_at_ms: number,
    profile_picture_url: string,
    profile_data: string
  ) {
    {
      try {
        const access_token_expires_at = new Date(
          Date.now() + access_token_expires_at_ms * 1000
        );

        access_token_expires_at.setDate(access_token_expires_at.getDate() - 10);

        const seller = await prisma.seller.findFirst({
          where: { ig_name: ig_username, deleted: !true },
        });

        let sellerRes = null;
        let thumbnail_path = null;

        if (seller?.thumbnail_path) {
          thumbnail_path = seller.thumbnail_path;
        }

        if (!seller?.thumbnail_path && profile_picture_url) {
          let uploadRes =
            await this.downloadAndSaveSellerProfilePictureFromInstagram(
              profile_picture_url
            );
          thumbnail_path = uploadRes.name;
        }

        if (!thumbnail_path) {
          thumbnail_path = "fa55e77e-7dcc-44b5-a0fd-c334db30f3c2.jpg";
        }

        console.log("thumbnail_path", thumbnail_path);

        if (seller) {
          sellerRes = await prisma.seller.update({
            where: {
              id: seller.id,
              deleted: !true,
            },
            data: {
              ig_id: ig_username,
              ig_name: ig_username,
              name: name,
              ig_token: access_token,
              ig_token_expires_at: access_token_expires_at,
              profile_data: profile_data,
              thumbnail_path: thumbnail_path,
            },
          });
        } else {
          sellerRes = await prisma.seller.create({
            data: {
              ig_id: ig_username,
              ig_name: ig_username,
              name: name,
              ig_token: access_token,
              ig_token_expires_at: access_token_expires_at,
              allow_sync: true,
              type: "agent",
              last_sync_date: new Date("2019-01-01"),
              allow_sync_profile_data: true,
              profile_data: profile_data,
              active: false,
              thumbnail_path: thumbnail_path,
            },
          });

          TelegramService.sendMessage(
            `Instagram account ${ig_username} was created`
          );
        }

        return { success: true, message: "User added", user: sellerRes };
      } catch (error) {
        throw error;
      }
    }
  }

  public static async getSellerByIgAccount(ig_username: string) {
    let seller = await prisma.seller.findFirst({
      where: { ig_name: ig_username, deleted: !true },
    });

    return seller;
  }

  public static async getSellerByUuid(
    uuid: string,
    select?: any
  ): Promise<any> {
    let seller = await prisma.seller.findFirst({
      select: select,
      where: { uuid: uuid, deleted: !true },
    });
    return seller;
  }

  public static async linkIGAccount(
    sellerId: number,
    ig_username: string,
    access_token: string,
    access_token_expires_at_ms: number
  ) {
    {
      try {
        const access_token_expires_at = new Date(
          Date.now() + access_token_expires_at_ms * 1000
        );

        access_token_expires_at.setDate(access_token_expires_at.getDate() - 10);

        let sellerRes = await prisma.seller.update({
          where: { id: sellerId },
          data: {
            ig_id: ig_username,
            ig_name: ig_username,
            ig_token: access_token,
            ig_token_expires_at: access_token_expires_at,
            allow_sync: true,
          },
        });
        TelegramService.sendMessage(
          `Instagram account ${ig_username} linked to seller ${sellerRes.id}`
        );
        return {
          success: true,
          message: "Instagram account linked",
          user: sellerRes,
        };
      } catch (error) {
        throw error;
      }
    }
  }

  static async updateSellerInfo(uuid: string): Promise<Seller> {
    let seller = await prisma.seller.findFirst({
      where: {
        uuid: uuid,
        deleted: !true,
      },
    });

    if (!seller) {
      throw new Error("Seller not found");
    }

    try {
      let sellerInfo = await SellerService.getInstagramSellerInfo(seller.ig_id);

      let phoneNumber = seller.phone;
      let whatsAppNumber = seller.whatsapp_number;

      if (seller.allow_sync_profile_data) {
        if (
          seller?.last_profile_data_update?.getTime() >
          new Date().getTime() - 30 * 24 * 60 * 60 * 1000 // 30 days
        ) {
          return seller;
        }

        if (sellerInfo.business_discovery.biography) {
          phoneNumber = SellerService.extractPhoneNumber(
            sellerInfo.business_discovery.biography
          );
        }

        if (sellerInfo.business_discovery.website) {
          whatsAppNumber =
            await SellerService.getWhatsAppNumberFromFromWhatsAppURL(
              sellerInfo.business_discovery.website
            );
        }
      }

      if (!phoneNumber && whatsAppNumber) {
        phoneNumber = "0" + whatsAppNumber.slice(-9);
      }

      if (!whatsAppNumber && phoneNumber) {
        whatsAppNumber = "255" + phoneNumber.slice(-9);
      }

      let downloadedMedia = await FilesService.download(
        sellerInfo.business_discovery.profile_picture_url,
        "image",
        {
          resizeWidth: 1000,
          aspectRation: {
            width: 4,
            height: 3,
          },
        },
        ["public", "dp"]
      );

      if (downloadedMedia) {
        seller = await prisma.seller.update({
          where: {
            uuid: uuid,
          },
          data: {
            last_profile_data_update: new Date(),
            profile_data: JSON.stringify(sellerInfo.business_discovery),
            thumbnail_path: downloadedMedia.name,
            phone: phoneNumber,
            whatsapp_number: whatsAppNumber,
            name: sellerInfo.business_discovery.name,
          },
        });
      }
    } catch (e) {
      console.log("Error updating seller info", e);
    }

    return seller;
  }

  static async updateExtraSellerInfo(uuid: string): Promise<Seller> {
    let seller = await prisma.seller.findFirst({
      where: {
        uuid: uuid,
        deleted: !true,
      },
    });

    if (!seller) {
      throw new Error("Seller not found");
    }

    let sellerInfo = await SellerService.getInstagramSellerInfo(seller.ig_id);

    let phoneNumber = seller.phone;
    let whatsAppNumber = seller.whatsapp_number;

    if (seller.allow_sync_profile_data) {
      if (
        seller?.last_profile_data_update?.getTime() >
        new Date().getTime() - 30 * 24 * 60 * 60 * 1000 // 30 days
      ) {
        return seller;
      }

      if (sellerInfo.business_discovery.biography) {
        phoneNumber = SellerService.extractPhoneNumber(
          sellerInfo.business_discovery.biography
        );
      }

      if (sellerInfo.business_discovery.website) {
        whatsAppNumber =
          await SellerService.getWhatsAppNumberFromFromWhatsAppURL(
            sellerInfo.business_discovery.website
          );
      }
    }

    if (!phoneNumber && whatsAppNumber) {
      phoneNumber = "0" + whatsAppNumber.slice(-9);
    }

    if (!whatsAppNumber && phoneNumber) {
      whatsAppNumber = "255" + phoneNumber.slice(-9);
    }

    let downloadedMedia = await FilesService.download(
      sellerInfo.business_discovery.profile_picture_url,
      "image",
      {
        resizeWidth: 1000,
        aspectRation: {
          width: 4,
          height: 3,
        },
      },
      ["public", "dp"]
    );

    if (downloadedMedia) {
      seller = await prisma.seller.update({
        where: {
          uuid: uuid,
        },
        data: {
          last_profile_data_update: new Date(),
          profile_data: JSON.stringify(sellerInfo.business_discovery),
          thumbnail_path: downloadedMedia.name,
          phone: phoneNumber,
          whatsapp_number: whatsAppNumber,
          name: sellerInfo.business_discovery.name,
        },
      });
    }

    return seller;
  }

  static async getSellersByUserId(userId: number) {
    let sellerUser = await prisma.sellerUser.findMany({
      where: {
        user_id: userId,
        deleted: !true,
      },
    });

    if (sellerUser.length === 0) {
      return [];
    }

    let sellers = await prisma.seller.findMany({
      where: {
        id: {
          in: sellerUser.map((su) => su.seller_id),
        },
        deleted: !true,
      },
    });

    return sellers;
  }

  static async getSellerUsersBySellerId(sellerId: number) {
    let sellerUser = await prisma.sellerUser.findMany({
      where: {
        seller_id: sellerId,
        deleted: !true,
      },
    });

    let users = await prisma.user.findMany({
      select: {
        id: true,
        email: true,
        name: true,
      },
      where: {
        id: {
          in: sellerUser.map((su) => su.user_id),
        },
        deleted: !true,
      },
    });

    return users;
  }

  static async createSellerAccount(
    userId: number,
    name: string,
    email: string
  ) {
    let seller = await prisma.seller.create({
      data: {
        name: name,
        email_address: email,
        active: false,
        allow_sync: false,
        allow_sync_profile_data: false,
        type: "agent",
      },
    });

    let sellerUser = await this.linkUserToSeller(userId, seller.id);

    return seller;
  }

  static async linkUserToSeller(userId: number, sellerId: number) {
    let sellerUser = await prisma.sellerUser.create({
      data: {
        user_id: userId,
        seller_id: sellerId,
      },
    });

    TelegramService.sendMessage(`User ${userId} linked to seller ${sellerId}`);

    return sellerUser;
  }

  static async deleteSellerAccount(seller: Seller, deleted_by: string) {
    let deleted_seller = await prisma.seller.update({
      where: {
        uuid: seller.uuid,
      },
      data: {
        active: false,
        deleted: true,
        deleted_at: new Date(),
        deleted_by: deleted_by,
      },
    });

    TelegramService.sendMessage(
      `Seller ${deleted_seller.name} deleted by ${deleted_by}`
    );

    console.log("Deleted seller", deleted_seller.name);

    await ListingController.deleteAllSellerPosts(deleted_seller, deleted_by);

    return seller;
  }

  static async downloadAndSaveSellerProfilePictureFromInstagram(
    profile_url: string
  ) {
    let downloadedMedia = await FilesService.download(
      profile_url,
      "image",
      {
        resizeWidth: 300,
        aspectRation: {
          width: 1,
          height: 1,
        },
      },
      ["public", "dp"]
    );

    return downloadedMedia;
  }
}
