import { ListingData } from "@/server/interfaces/ListingData";
import React from "react";
import ListingItem from "./ListItem";

const ListingsList = ({
  Listings,
  canApprove,
}: {
  Listings: ListingData[];
  canApprove?: boolean;
}) => {
  return (
    <div className="gap-8 space-y-8 lg:columns-4 sm:columns-3 columns-2">
      {Listings.map((Listing: ListingData, index: number) => (
        <div className="" key={index}>
          <ListingItem canApprove={canApprove} Listing={Listing} />
        </div>
      ))}
    </div>
  );
};

export default ListingsList;
