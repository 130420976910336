import { ListingData } from "@/server/interfaces/ListingData";
import React from "react";
import ListingsList from "./Lists";
import { useRouter } from "next/router";
import { useTranslations } from "@/TranslationContext";
import AppDialog from "../AppDialog";

const EditActions = ({
  Listing,
  canApprove,
  onAction,
}: {
  Listing: ListingData;
  canApprove?: boolean;
  onAction?: (action: string, Listing: ListingData) => void;
}) => {
  const router = useRouter();
  const t = useTranslations();

  const { locale } = router;

  const ls = locale === "sw" ? "" : `/${locale}`;

  const deletePost = () => {
    //delete post
  };
  return (
    <>
      <div className="flex mb-2 gap-2 mt-4 flex-wrap">
        <button
          className="border border-red-500 text-red-500  px-3 py-1 rounded-md text-xs"
          onClick={(e) => {
            e.stopPropagation();
            onAction("delete", Listing);
          }}
        >
          Delete
        </button>
        <button
          className="bg-green-600 text-white flex-1 px-3 py-1 rounded-md text-xs"
          onClick={(e) => {
            e.stopPropagation();
            window.location.href = `post?id=${Listing.uuid}`;
          }}
        >
          Edit
        </button>
        {canApprove && (
          <button
            className="bg-green-600 text-white flex-1 px-3 py-1 rounded-md text-xs"
            onClick={(e) => {
              e.stopPropagation();
              window.location.href = `app-admin/list-approval/${Listing.uuid}`;
            }}
          >
            Approval
          </button>
        )}
        {/* 
        {Listing.publish_status === "draft" && (
          <button
            className="bg-green-600 text-white  px-3 py-1 rounded-md text-xs"
            onClick={(e) => {
              e.stopPropagation();
              onAction('publish',Listing);
            }}
          >
            Publish
          </button>
        )}

        {Listing.publish_status === "published" && (
          <button
            className="bg-red-600 text-white  px-3 py-1 rounded-md text-xs"
            onClick={(e) => {
              e.stopPropagation();
              onAction('un-publish',Listing);
            }}
          >
            Un-publish
          </button>
        )} */}
      </div>
    </>
  );
};

export default EditActions;
