import { ListingData } from "@/server/interfaces/ListingData";
import React from "react";
import ListingsHelper from "@/utils/ListingsHelper";
import { Listing } from "@prisma/client";
import Image from "next/image";
import { useRouter } from "next/router";
import { useTranslations } from "@/TranslationContext";
import StatsHelper from "@/utils/StatsHelper";
import EditActions from "./EditActions";

const ListingItem = ({
  Listing,
  canEdit,
  canApprove,
  onAction,
}: {
  Listing: ListingData;
  canEdit?: boolean;
  canApprove?: boolean;
  onAction?: (action: string, Listing: ListingData) => void;
}) => {
  let imagePath = ListingsHelper.getImagePath(Listing as unknown as Listing);
  let priceData = ListingsHelper.getPriceLabels(Listing);
  let labels = ListingsHelper.getLabels(Listing);

  const [containerWidth, setContainerWidth] = React.useState(250);

  const router = useRouter();
  const t = useTranslations();

  const { locale } = router;

  const ls = locale === "sw" ? "" : `/${locale}`;
  const title = locale === "sw" ? Listing.title : Listing.title_en;

  let slug = ListingsHelper.createSlugFromTitle(title);

  let description = Listing.description.replace(/(<([^>]+)>)/gi, "");

  //shorten description if it is too long
  if (description.length > 100) {
    description = description.substring(0, 100) + "...";
  }

  const addToList = (id: number) => {
    //create a list of ids and store it in local storage
    let list = localStorage.getItem("list");
    let listArray = [];
    if (list) {
      listArray = JSON.parse(list);
    }

    if (listArray.includes(id)) {
      return;
    }

    if (listArray.length > 6) {
      alert("Maximum of 6 items allowed in list");
      return;
    }

    listArray.push(id);
    localStorage.setItem("list", JSON.stringify(listArray));
  };

  React.useEffect(() => {
    let width = document.querySelector(".item-wrapper")?.clientWidth;
    if (width) {
      width = width < 250 ? width : 250;
      setContainerWidth(width);
    }
  }, []);

  return (
    <div
      className="group item-wrapper rounded-xl bg-white  shadow hover:shadow-xl overflow-hidden ease-in-out duration-500 cursor-pointer"
      onClick={() => {
        StatsHelper.registerListingClick(Listing.id);
        window.location.href = `${ls}/listings/${slug}/${Listing.uuid}`;
      }}
    >
      <div className="relative">
        <Image
          src={imagePath}
          alt={title}
          width={containerWidth}
          height={containerWidth}
          title={title}
        />
      </div>
      <div
        className="p-3 overflow-hidden"
        style={{ width: containerWidth + "px" }}
      >
        <div className="mb-2">
          {canEdit && (
            <div className="flex mb-2">
              <div className="text-xs text-gray-500 border border-gray-600 rounded px-1">
                {Listing.publish_status.toUpperCase()}
              </div>
            </div>
          )}
          <a
            className=" hover:text-green-600 font-medium text-gray-700 l"
            href={`${ls}/listings/${slug}/${Listing.uuid}`}
            title={title}
            onClick={(e) => {
              StatsHelper.registerListingClick(Listing.id);
              e.stopPropagation();
            }}
          >
            <h2 className=" leading-0 font-bold">{title}</h2>
          </a>
        </div>
        {labels.length > 0 && (
          <ul className="pb-1 text-xs flex items-center list-none">
            {labels.map((label, index) => (
              <li key={index} className="flex items-center me-4 text-gray-400">
                {index > 0 && (
                  <i className="uil uil-compress-arrows text-2xl me-2 text-green-600"></i>
                )}
                <span>{label}</span>
              </li>
            ))}
          </ul>
        )}

        <div className="flex justify-between items-center list-none">
          <div>
            <p className="font-bold">
              <span className="text-green-600">
                {priceData.currency} {priceData?.price?.toLocaleString()}{" "}
              </span>
              {priceData.label && (
                <span className="text-slate-400 text-sm">
                  {priceData.label}
                </span>
              )}
            </p>
          </div>
        </div>

        <p className="text-xs text-gray-500 break-words ">{description}</p>

        {(canEdit || canApprove) && (
          <EditActions
            Listing={Listing}
            canApprove={canApprove}
            onAction={(action: string, Listing: ListingData) => {
              if (onAction) {
                onAction(action, Listing);
              }
            }}
          />
        )}
      </div>
      {/* <div
        className="bg-green-600 text-white text-center py-2 cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          addToList(Listing.id); 
        }}
        >Add to list</div> */}
    </div>
  );
};

export default ListingItem;
