"use client";
import React, { useEffect, useState } from "react";
import a from "next/link";
import { LuMenu } from "react-icons/lu";
import { MdClose } from "react-icons/md";
import Image from "next/image";
import { useTranslations } from "@/TranslationContext";
import router, { useRouter } from "next/router";
import ProfileButton from "./ProfileButton";
import Link from "next/link";

const Navbar = (props: any) => {
  const { navClass, topnavClass } = props;
  const [isOpen, setMenu] = useState(true);
  const t = useTranslations();
  const router = useRouter();

  const { locale } = router;
  const ls = locale === "sw" ? "" : `/${locale}`;

  const toggleMenu = () => {
    setMenu(!isOpen);
    if (document.getElementById("navigation")) {
      var elements: any = document
        .getElementById("navigation")
        ?.getElementsByTagName("a");

      for (var i = 0, len = elements?.length; i < len; i++) {
        elements[i].onclick = function (elem) {
          if (elem.target.getAttribute("href") !== "") {
            if (elem.target.nextElementSibling) {
              var submenu = elem.target.nextElementSibling.nextElementSibling;
              submenu.classList.toggle("open");
            }
          }
        };
      }
    }
  };

  const getClosest = (elem: any, selector: any) => {
    if (!Element.prototype.matches) {
      Element.prototype.matches =
        Element.prototype.webkitMatchesSelector ||
        function (s) {
          var matches = (this.document || this.ownerDocument).querySelectorAll(
              s
            ),
            i = matches.length;
          while (--i >= 0 && matches.item(i) !== this) {}
          return i > -1;
        };
    }

    // Get the closest matching element
    for (; elem && elem !== document; elem = elem.parentNode) {
      if (elem.matches(selector)) return elem;
    }
    return null;
  };

  useEffect(() => {
    var menuItems: any = document.getElementsByClassName("sub-menu-item");
    if (menuItems) {
      var matchingMenuItem = null;
      for (var idx = 0; idx < menuItems.length; idx++) {
        if (menuItems[idx].href === window.location.href) {
          matchingMenuItem = menuItems[idx];
        }
      }

      if (matchingMenuItem) {
        matchingMenuItem.classList.add("active");

        var immediateParent = getClosest(matchingMenuItem, "li");

        if (immediateParent) {
          immediateParent.classList.add("active");
        }

        var parent = getClosest(immediateParent, ".child-menu-item");
        if (parent) {
          parent.classList.add("active");
        }

        var parent = getClosest(parent || immediateParent, ".parent-menu-item");

        if (parent) {
          parent.classList.add("active");

          var parentMenuitem = parent.querySelector(".menu-item");
          if (parentMenuitem) {
            parentMenuitem.classList.add("active");
          }

          var parentOfParent = getClosest(parent, ".parent-parent-menu-item");
          if (parentOfParent) {
            parentOfParent.classList.add("active");
          }
        } else {
          var parentOfParent = getClosest(
            matchingMenuItem,
            ".parent-parent-menu-item"
          );
          if (parentOfParent) {
            parentOfParent.classList.add("active");
          }
        }
      }
    }

    var elements: any = document
      .getElementById("navigation")
      .getElementsByTagName("a");
    for (var i = 0, len = elements.length; i < len; i++) {
      elements[i].onclick = function (elem) {
        if (elem.target.getAttribute("href") === "#") {
          var submenu = elem.target.nextElementSibling.nextElementSibling;
          submenu.classList.toggle("open");
        }
      };
    }
  }, []);

  return (
    <nav
      id="topnav"
      className={`flex defaultscroll bg-black is-sticky ${
        topnavClass ? topnavClass : ""
      }`}
    >
      <div
        className={`flex w-full justify-between items-center ${
          topnavClass !== "" && topnavClass !== undefined
            ? "container-fluid md:px-8 px-3"
            : "container"
        }`}
      >
        {navClass === "" || navClass === undefined ? (
          <a className="logo" href={`${ls}/`} title="MakaziMpaya">
            <Image
              src="/logo.png"
              className="nav-logo"
              alt="MakaziMpaya"
              width={72}
              height={40}
              title="MakaziMpaya"
            />
          </a>
        ) : (
          <a className="logo " href={`${ls}/`} title="MakaziMpaya">
            <Image
              src="/logo.png"
              alt="MakaziMpaya"
              className="nav-logo"
              width={72}
              height={40}
              title="MakaziMpaya"
            />
          </a>
        )}

        <div
          id="navigation"
          className={`${isOpen === true ? "hidden" : "block"}`}
        >
          <ul
            className={`navigation-menu  ${
              navClass === "" || navClass === undefined ? "" : "nav-light"
            }   ${
              topnavClass !== "" && topnavClass !== undefined
                ? "justify-center"
                : "justify-end"
            }`}
          >
            <li className="has-submenu parent-parent-menu-item">
              <a href="#" className="sub-menu-item" title={t.plots}>
                {t.plots}
              </a>
              <span className="menu-arrow"></span>
              <ul className="submenu">
                <li>
                  <a
                    href={`${ls}/listings?property_type=residential_plot`}
                    className="sub-menu-item"
                    title={t.residentialPlots}
                  >
                    {t.residentialPlots}
                  </a>
                </li>
                <li>
                  <a
                    href={`${ls}/listings?property_type=industrial_plot`}
                    className="sub-menu-item"
                    title={t.industrialPlots}
                  >
                    {t.commercialPlots}
                  </a>
                </li>
                <li>
                  <a
                    href={`${ls}/listings?property_type=farm`}
                    className="sub-menu-item"
                    title={t.farms}
                  >
                    {t.farms}
                  </a>
                </li>
                <li>
                  <a
                    href={`${ls}/listings?payment_type=installments`}
                    className="sub-menu-item"
                    title={t.payInInstallments}
                  >
                    {t.payInInstallments}
                  </a>
                </li>
              </ul>
            </li>
            <li className="has-submenu parent-parent-menu-item">
              <a href="#" className="sub-menu-item" title={t.houses}>
                {t.houses}
              </a>
              <span className="menu-arrow"></span>
              <ul className="submenu">
                <li>
                  <a
                    href={`${ls}/listings?listing_type=rent&property_type=house`}
                    className="sub-menu-item"
                    title={t.houseForRent}
                  >
                    {t.houseForRent}
                  </a>
                </li>
                <li>
                  <a
                    href={`${ls}/listings?listing_type=sale&property_type=house`}
                    className="sub-menu-item"
                    title={t.houseForSale}
                  >
                    {t.houseForSale}
                  </a>
                </li>
                <li>
                  <a
                    href={`${ls}/listings?listing_type=rent&property_type=retail_space`}
                    className="sub-menu-item"
                    title={t.retailSpace}
                  >
                    {t.retailSpace}
                  </a>
                </li>
                <li>
                  <a
                    href={`${ls}/listings?listing_type=rent&property_type=office_space`}
                    className="sub-menu-item"
                    title={t.officeSpace}
                  >
                    {t.officeSpace}
                  </a>
                </li>
              </ul>
            </li>
            <li className="has-submenu parent-parent-menu-item">
              <Link href="#" className="sub-menu-item" title={t.buy}>
                {t.buy}
              </Link>
              <span className="menu-arrow"></span>
              <ul className="submenu">
                <li>
                  <a
                    href={`${ls}/listings?listing_type=sale&property_type=land`}
                    className="sub-menu-item"
                    title={t.plots}
                  >
                    {t.residentialPlots}
                  </a>
                </li>
                <li>
                  <a
                    href={`${ls}/listings?listing_type=sale&property_type=farm`}
                    className="sub-menu-item"
                    title={t.farms}
                  >
                    {t.farms}
                  </a>
                </li>
                <li>
                  <a
                    href={`${ls}/listings?listing_type=sale&property_type=house`}
                    className="sub-menu-item"
                    title={t.houses}
                  >
                    {t.houses}
                  </a>
                </li>
              </ul>
            </li>
            <li className="has-submenu parent-parent-menu-item">
              <a href="#" className="sub-menu-item" title={t.rent}>
                {t.rent}
              </a>
              <span className="menu-arrow"></span>
              <ul className="submenu">
                <li>
                  <a
                    href={`${ls}/listings?listing_type=rent&property_type=house`}
                    className="sub-menu-item"
                    title={t.house}
                  >
                    {t.house}
                  </a>
                </li>
                <li>
                  <a
                    href={`${ls}/listings?listing_type=rent&property_type=retail_space`}
                    className="sub-menu-item"
                    title={t.retailSpace}
                  >
                    {t.retailSpace}
                  </a>
                </li>
                <li>
                  <a
                    href={`${ls}/listings?listing_type=rent&property_type=office_space`}
                    className="sub-menu-item"
                    title={t.officeSpace}
                  >
                    {t.officeSpace}
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a
                href={`${ls}/sellers`}
                className="sub-menu-item"
                title={t.sellers}
              >
                {t.agents}
              </a>
            </li>
            <li>
              <a
                href={`${ls}/agent-admin/post`}
                className=" !bg-[#16a34ae6] font-bold hover:text-white"
                title="Post"
              >
                + POST
              </a>
            </li>
          </ul>
        </div>
        <div className="flex items-center space-x-3">
          <ProfileButton></ProfileButton>
          <button
            className="text-white text-3xl lg:hidden"
            onClick={toggleMenu}
          >
            {isOpen && <LuMenu />}
            {!isOpen && <MdClose />}
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
